<template>
    <div class="user-content">
        <div class="user-info-title">我的求购
          <el-button type="primary" style="margin-bottom:20px; background: #0960bd;padding: 8px 14px; float: right" @click="formDialogRef.openNewDialog()">发布求购</el-button>
        </div>
        <div class="user-info-list">

            <el-table :data="data.tableData" style="width: 100%">
                <el-table-column type="index" label="序号" width="80">
                </el-table-column>
                <el-table-column prop="title" label="标题" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <RouterLink :to="url + scope.row.id">
                      {{scope.row.title}}
                    </RouterLink>
                  </template>
                </el-table-column>
                <el-table-column prop="updateTime" label="发布时间" width="180">
                </el-table-column>
				<el-table-column prop="district" label="所属行政区"  width="140">
					<template #default="scope">
						{{ findLabel(land_area, scope.row.district) }}
					</template>
				</el-table-column>
              <el-table-column prop="auditStatus" label="审核状态" width="140" show-overflow-tooltip>
                <template #default="scope">
                  {{ scope.row.auditStatus === 2 ? '审核通过' : scope.row.auditStatus === 3 ? '审核不通过' : '待审核' }}
                </template>
              </el-table-column>
                <!-- <el-table-column prop="action" label="操作">
                </el-table-column> -->
  <!--              <el-table-column label="操作" width="150">-->
  <!--                <template #default="scope">-->
  <!--                  <el-button  text type="primary" @click="formDialogRef.openNewDialog(scope.row.id)">查看详情-->
  <!--                   </el-button>-->
  <!--                </template>-->
  <!--              </el-table-column>-->
            </el-table>
            <div class="user-info-list-page">
                <el-pagination background  style="float: right" layout="total,  prev, pager, next, jumper"  :page-count="data.totalPage" :current-page="data.currentPage" @current-change="pageChange" >
                </el-pagination>
            </div>
        </div>
        <form-dialog ref="formDialogRef" @refresh="pageChange" />
    </div>
</template>
<script setup>
import { reactive,onMounted,ref,defineAsyncComponent } from 'vue'
import CommonService from '@/service/CommonService';
import { useDict, findLabel } from "@/hooks/dict";
import {RouterLink} from "vue-router";
const formDialog = defineAsyncComponent(() => import('./need_add.vue'));

const formDialogRef = ref();
const { land_area } = useDict('land_area')

const data = reactive({
    pageSize:10,
    currentPage:1,
    totalPage:1,
    tableData: [
    ]
})
const url = '/demand/detail/';
const pageChange = (page) =>{

    loadLand(page);
}
const loadLand = (page) =>{
    const userId = localStorage.getItem('user_id');
    CommonService.getDemandLandList({
        createBy:userId,
        current:page
    }).then((res)=>{
      if(res.code === 0){
            data.tableData = res.data.records;
            data.totalPage = res.data.pages;
            data.currentPage = res.data.current;
        }
    })
}

onMounted(() => {
    loadLand(1);
})

</script>
